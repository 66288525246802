var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "page-header-index-wide page-header-wrapper-grid-content-main",
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 7 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c("div", { staticClass: "account-center-avatarHolder" }, [
                    _c("div", { staticClass: "avatar" }, [
                      _c("img", { attrs: { src: _vm.avatar } }),
                    ]),
                    _c("div", { staticClass: "username" }, [
                      _vm._v(_vm._s(_vm.user.userName)),
                    ]),
                    _c("div", { staticClass: "bio" }, [
                      _vm._v(_vm._s(_vm.roleGroup)),
                    ]),
                  ]),
                  _c("div", { staticClass: "account-center-detail" }, [
                    _c(
                      "p",
                      [
                        _c("a-icon", {
                          staticClass: "info",
                          attrs: { type: "phone" },
                        }),
                        _vm._v(_vm._s(_vm.user.phonenumber) + " "),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      [
                        _c("a-icon", {
                          staticClass: "info",
                          attrs: { type: "mail" },
                        }),
                        _vm._v(_vm._s(_vm.user.email) + " "),
                      ],
                      1
                    ),
                    _vm.user.dept
                      ? _c(
                          "p",
                          [
                            _c("a-icon", {
                              staticClass: "info",
                              attrs: { type: "apartment" },
                            }),
                            _vm._v(
                              _vm._s(_vm.user.dept.deptName) +
                                " / " +
                                _vm._s(_vm.postGroup) +
                                " "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      [
                        _c("a-icon", {
                          staticClass: "info",
                          attrs: { type: "calendar" },
                        }),
                        _vm._v(_vm._s(_vm.user.createTime) + " "),
                      ],
                      1
                    ),
                  ]),
                  _c("a-divider"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }